/**
 * Authority types that are known to the system.
 */
export class KnownAuthorityType {

  /**
   * An Agent Authority.
   */
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public static Agent = 'Agent';

  /**
   * A County Council Authority.
   */
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public static CountyCouncil = 'County Council';

  /**
   * A Global Local Authority.
   */
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public static Global = 'Global';

  /**
   * A Highways Authority.
   */
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public static Highways = 'Highways';

  /**
   * A Local Authority supplier.
   */
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public static LocalAuthority = 'Local Authority';

  /**
   * A National Park Authority.
   */
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public static NationalPark = 'National Park';

  /**
   * A Water Company Authority.
   */
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public static WaterCompany = 'Water Company';
}

/**
 * Authority types that are used internally for presentation.
 */
export class PresentationAuthorityType {

  /**
   * An Authority that can be edited.
   */
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public static EditableAuthority = 'EditableAuthority';
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';


/**
 * To be implemented in components that are guarded by the {@link CanDeactivateGuard}.
 */
export interface CanComponentDeactivate {
  /**
   * Determines whether the component can be deactivated.
   *
   * @returns A flag indicating whether the component can be deactivated.
   */
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean;
}

/**
 * Injectable deactivate guard to be used when deactivating components.
 */
@Injectable({
  providedIn: 'root'
})
export class CanDeactivateGuard  {

  /**
   * Checks whether the current user has permission to deactivate the requested route.
   * 
   * @param component The component to check.
   * @param currentRoute The current Route.
   * @param currentState The current State.
   * @param nextState The next state.
   * @returns A value to indicates if route can be deactivated.
   */
  public canDeactivate(component: CanComponentDeactivate,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot):
    boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return !component || (component.canDeactivate ? component.canDeactivate() : true);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { DateConverterInterceptor } from './date-converter.interceptor';
import { TokenRefreshInterceptor } from './token-refresh.interceptor';

/**
 * Module to provide application api handlers.
 */
@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenRefreshInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: DateConverterInterceptor, multi: true }
  ]
})
export class ApiModule { }

/**
 * Details of Land Charges summary mode types returned by API.
 */
export enum LandChargesSummaryModeType {
  /**
   * The categories value.
   */
  categories = 'Categories',

  /**
   * The parts value.
   */
  parts = 'Parts',
}

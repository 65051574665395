/**
 * Available security group names.
 */
export enum SecurityGroup {

  /**
   * The group that is allowed access to RLAS management screens.
   */
  rlasConfigAccess = 'RlasConfigAccess',

  /**
   * The group that is allowed add, activate and delete configurations in RLAS management.
   */
  rlasConfigEdit = 'RlasConfigEdit',

  /**
   * The group that is allowed access to split config tab in RLAS management.
   */
  rlasSplitConfigAccess = 'RlasSplitConfigAccess',

  /**
   * The group that is allowed to edit split config in RLAS management.
   */
  rlasSplitConfigEdit = 'RlasSplitConfigEdit',

  /**
   * The group that is allowed access to default answers tab in RLAS management.
   */
  rlasDefaultAnswersAccess = 'RlasDefaultAnswersAccess',

  /**
   * The group that is allowed to edit default answers in RLAS management.
   */
  rlasDefaultAnswersEdit = 'RlasDefaultAnswersEdit',

  /**
   * The group that is allowed access to default documents tab in RLAS management.
   */
  rlasDefaultDocumentsAccess = 'RlasDefaultDocumentsAccess',

  /**
   * The group that is allowed to edit default documents in RLAS management.
   */
  rlasDefaultDocumentsEdit = 'RlasDefaultDocumentsEdit',

  /**
   * The group that is allowed editing global configuration in RLAS management.
   */
  rlasGlobalAuthorityEdit = 'RlasGlobalAuthorityEdit',

  /**
   * The group that is allowed to access the help text tab in RLAS management.
   */
  rlasHelpTextAccess = 'RlasHelpTextAccess',

  /**
   * The group that is allowed to edit help text in RLAS management.
   */
  rlasHelpTextEdit = 'RlasHelpTextEdit',

  /**
   * The group that is allowed to access the Shortcodes tab in RLAS management.
   */
  rlasShortcodesAccess = 'RlasShortcodesAccess',

  /**
   * The group that is allowed to edit Shortcodes in RLAS management.
   */
  rlasShortcodesEdit = 'RlasShortcodesEdit',

  /**
   * The group that is allowed to access the LA Details tab in RLAS management.
   */
  rlasLaDetailsAccess = 'RlasLaDetailsAccess',

  /**
   * The group that is allowed to edit LA Details in RLAS management.
   */
  rlasLaDetailsEdit = 'RlasLaDetailsEdit',

  /**
   * The group that is allowed to access kaizen help text tab in RLAS management.
   */
  rlasKaizenHelpTextAccess = 'RlasKaizenHelpTextAccess',

  /**
   * The group that is allowed to edit kaizen help text in RLAS management.
   */
  rlasKaizenHelpTextEdit = 'RlasKaizenHelpTextEdit',

  /**
   * The group that is allowed to access the Data Rules in RLAS management.
   */
  rlasDataRulesAccess = 'RlasDataRulesAccess',

  /**
   * The group that is allowed to edit Data Rules in RLAS management.
   */
  rlasDataRulesEdit = 'RlasDataRulesEdit',

  /**
   * The group that is allowed to access Mapping in RLAS management.
   */
  rlasMappingAccess = 'RlasMappingAccess',

  /**
   * The group that is allowed to edit Mapping in RLAS management.
   */
  rlasMappingEdit = 'RlasMappingEdit',

  /**
   * The group that is allowed to access the Searcher Allocation in RLAS management.
   */
  rlasSearcherAllocationAccess = 'RlasSearcherAllocationAccess',

  /**
   * The group that is allowed to edit Searcher Allocation in RLAS management.
   */
  rlasSearcherAllocationEdit = 'RlasSearcherAllocationEdit',

  /**
   * The group that is allowed to access Toolbox in RLAS management.
   */
  rlasToolboxAccess = 'RlasToolboxAccess',

  /**
   * The group that is allowed to edit Toolbox in RLAS management.
   */
  rlasToolboxEdit = 'RlasToolboxEdit',

  /**
   * The group that is allowed to access Development Help Text in RLAS management.
   */
  rlasDevelopmentHelpAccess = 'RlasDevelopmentHelpAccess',

  /**
   * The group that is allowed to edit Development Help Text in RLAS management.
   */
  rlasDevelopmentHelpEdit = 'RlasDevelopmentHelpEdit',

  /**
   * The group that is allowed access to Data Refresh in RLAS management.
   */
  rlasDataRefreshAccess = 'RlasDataRefreshAccess',

  /**
   * The group that is allowed to edit Data Refresh in RLAS management.
   */
  rlasDataRefreshEdit = 'RlasDataRefreshEdit',

  /**
   * The group that is allowed access to Auto Search in RLAS management.
   */
  rlasAutoSearchAccess = 'RlasAutoSearchAccess',

  /**
   * The group that is allowed to edit Auto Search in RLAS management.
   */
  rlasAutoSearchEdit = 'RlasAutoSearchEdit',

  /**
   * The group that is allowed access to Ace in RLAS management.
   */
  rlasAceAccess = 'RlasAceAccess',

  /**
   * The group that is allowed to edit Ace in RLAS management.
   */
  rlasAceEdit = 'RlasAceEdit',
}

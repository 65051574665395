import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ChatterSessionInterceptor } from './chatter-session-interceptor';

/**
 * Module for Chatter.
 */
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ChatterSessionInterceptor, multi: true }
  ]
})
export class ChatterModule { }

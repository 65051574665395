import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { Observable, of, interval, combineLatest } from 'rxjs';
import { catchError, tap, map, shareReplay, finalize } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

import { ChatterMessageType } from 'src/app/chatter/model';
import { DataStoreCollection, DataStoreObject, CollectionChangeData, DataObjectStatus, isDataStoreObject } from './model';
import { DataStoreModule } from './data-store.module';
import { ApiSubject } from './model/api-subject';
import { ChatterService } from 'src/app/chatter';
import * as __ from 'lodash';

export type ApiObservable<T> = Observable<T> & { refresh(): void };

/**
 * A service providing a data store backed by a restful API.
 */
export interface IDataStoreService {

  /**
   * Loads the data for an object in the store if it is not currently loaded and monitors the
   * data store object for changes.
   *
   * @param href The URI of the object to load.
   * @returns A hot observable that will emit once the data is loaded or to provide the existing
   *          value and again whenever the data changes.
   */
  loadObject<T>(href: string): Observable<DataStoreObject<T>>;

  /**
   * Loads the data for an object from the server without adding it to the dataStore.
   *
   * @param href The data store object to load.
   * @param params Any additional parameters to pass to the server.
   * @returns A shared observable that will emit once the data is loaded.
   */
  loadObjectNoCache<T>(href: string, params?: { [key: string]: any }): Observable<DataStoreObject<T>>;

  /**
   * Loads the data for a collection in the store if it is not currently loaded and monitors the
   * data store collection for changes.
   *
   * @param href The URI of the collection to load.
   * @returns A hot observable that will emit once the collection is loaded or to provide the existing
   *          value and again whenever the collection changes.
   */
  loadCollection<T>(href: string): Observable<DataStoreCollection<T>>;

  /**
   * For a given array of store objects generates an observable that emits a collection of all objects in the source array.
   * The returned observable will emit every time any of the objects change.
   *
   * @template T The type of objects contained in the collection.
   * @param storeObjects The source object array.
   * @returns An observable that emits a collection of objects contained in the source array.
   */
  loadStoreObjectsData<T>(storeObjects: DataStoreObject<T>[]): Observable<T[]>;

  /**
   * Creates a collection of observables emitting object data from a collection of store objects.
   *
   * @template T The type of objects contained in the collection.
   * @param storeObjects The objects to load.
   * @returns A collection of observables that will load source objects.
   */
  loadStoreObjects<T>(storeObjects: DataStoreObject<T>[]): Observable<T>[];

  /**
   * Creates an observable that loads store object.
   *
   * @template T The type of object to load.
   * @param storeObject The store object to load.
   * @returns An observable emitting object data when it is loaded or is changed.
   */
  loadStoreObject<T>(storeObject: DataStoreObject<T>): Observable<T>;
}

/**
 * A service providing a data store backed by a restful API.
 */
@Injectable({
  providedIn: DataStoreModule
})
export class DataStoreService implements IDataStoreService {

  /**
   * The actual data store.
   */
  private dataStore = new Map<string, Map<string, ApiSubject<any>>>();

  /**
   * The Data Store Garbage Collector.
   */
  private garbageCollector = this.ngZone.runOutsideAngular(
    () => interval(environment.gcInterval * 1000).pipe(
      map(_ => [...this.dataStore.entries()].reduce(
        (x, [key, value]) => [...value.entries()].some(
          ([discriminator, store]) => store.isReadyForCollection
        ) ? [
          ...x,
          {
            href: key,
            discriminators: [...value.entries()].filter(
              ([discriminator, store]) => store.isReadyForCollection
            ).map(
              ([discriminator, store]) => discriminator
            )
          }
        ] : x,
        [] as { href: string; discriminators: string[] }[])
      )
    ).subscribe({
      next: candidates => {
        candidates.forEach(
          x => {
            x.discriminators.forEach(
              y => {
                this.dataStore.get(x.href).get(y).complete();
                this.dataStore.get(x.href).delete(y);
              }
            );
            if (this.dataStore.get(x.href).size === 0) {
              this.dataStore.delete(x.href);
            }
          }
        );
      }
    })
  );

  /**
   * Creates an instance of the data store service.
   *
   * @param http The {@link HttpClient} for the service to use.
   * @param chatterService The {@link ChatterService} for the service to use.
   * @param ngZone The {@link NgZone} instance for the service to use.
   */
  constructor(
    private http: HttpClient,
    private chatterService: ChatterService,
    private ngZone: NgZone
  ) {
    // Replacing spaces is a hack to make signalR notifications for local authorities work correctly in Linux containers.
    // For some reason the URI spaces are not encoded in SignalR messages but are encoded in API responses.
    // On local system URIs are encoded on both API and SingnalR messages.
    this.chatterService.addListener<DataStoreObject<any>>(
      ChatterMessageType.objectChanged,
      (object) => { object.href = object.href.replace(/\s/g, '%20'); this.onObjectUpdated(object); }
    );

    this.chatterService.addListener<CollectionChangeData<any>>(
      ChatterMessageType.objectCollectionChanged,
      (change) => { change.collectionHref = change.collectionHref.replace(/\s/g, '%20'); this.onCollectionUpdated(change); }
    );
  }

  /**
   * Loads the data for an object from the server without adding it to the dataStore.
   *
   * @param href The data store object to load.
   * @param params Any additional parameters to pass to the server.
   * @returns A shared observable that will emit once the data is loaded.
   */
  public loadObjectNoCache<T>(
    href: string,
    params?: { [key: string]: any }
  ): Observable<DataStoreObject<T>> {
    const loader = new ApiSubject<DataStoreObject<T>>(this.http, href, params);
    return loader.subject$.pipe(
      tap(x => this.storeSubObjectsFrom(x.data)),
      catchError((x: unknown) => {
        if (x instanceof HttpErrorResponse && x.status === 404) {
          return of({
            status: DataObjectStatus.notFound,
            href
          } as DataStoreObject<T>);
        } else {
          throw x;
        }
      })
    );
  }

  /**
   * Add a new object to the data store and save it's data. An HTTP POST request will be made on the
   * parent key and the payload of the response will be used to update the data of the object and the
   * object is placed into the data store.
   *
   * @param parentKey The resource to add the object to.
   * @param objectData The object to add to the specified resource.
   * @returns A cold observable which will emit when the update completes.
   */
  public add<T, G>(parentKey: string, objectData: G): Observable<DataStoreObject<T>> {
    return this.http.post<DataStoreObject<T>>(parentKey, objectData)
      .pipe(
        map(data => {
          data.status = DataObjectStatus.loaded;
          const storeObject = this.dataStore.get(data.href);
          if (!storeObject) {
            this.dataStore.set(data.href, new Map<string, ApiSubject<any>>());
          }
          this.storeSubObjectsFrom(data);
          return data;
        }),
        finalize(() => __)
      );
  }

  /**
   * Loads the data for an object in the store if it is not currently loaded and monitors the
   * data store object for changes.
   *
   * @param href The URI of the data to load.
   * @param options The options.
   * @param options.name The name of this instance of the data.
   * @param options.params The query parameters to use.
   * @returns A hot observable that will emit once the data is loaded or to provide the existing
   *          value and again whenever the data changes.
   */
  public loadObject<T>(
    href: string,
    options?: {
      name?: string;
      params?: { [key: string]: any };
    }
  ): Observable<DataStoreObject<T>> {
    const opts = options ? options : {};
    return this.getObject<T>(href, opts.name, opts.params);
  }

  /**
   * Loads the data for a collection in the store if it is not currently loaded and monitors the
   * data store collection for changes.
   *
   * @param href The URI of the data to load.
   * @param options The options.
   * @param options.name The name of this instance of the data.
   * @param options.params The query parameters to use.
   * @returns A hot observable that will emit once the collection is loaded or to provide the existing
   *          value and again whenever the collection changes.
   */
  public loadCollection<T>(
    href: string,
    options?: {
      name?: string;
      params?: { [key: string]: any };
    }
  ): Observable<DataStoreCollection<T>> {
    const opts = options ? options : {};
    return this.getCollection<T>(href, opts.name, opts.params);
  }

  /**
   * For a given array of store objects generates an observable that emits a collection of all objects in the source array.
   * The returned observable will emit every time any of the objects change.
   *
   * @template T The type of objects contained in the collection.
   * @param storeObjects The source object array.
   * @returns An observable that emits a collection of objects contained in the source array.
   */
  public loadStoreObjectsData<T>(storeObjects: DataStoreObject<T>[]): Observable<T[]> {
    return combineLatest(this.loadStoreObjects(storeObjects)).pipe(
      shareReplay({ refCount: false, bufferSize: 1 })
    );
  }

  /**
   * Creates a collection of observables emitting object data from a collection of store objects.
   *
   * @template T The type of objects contained in the collection.
   * @param storeObjects The objects to load.
   * @returns A collection of observables that will load source objects.
   */
  public loadStoreObjects<T>(storeObjects: DataStoreObject<T>[]): Observable<T>[] {
    return storeObjects.map(o => this.loadStoreObject(o));
  }

  /**
   * Creates an observable that loads store object.
   *
   * @template T The type of object to load.
   * @param storeObject The store object to load.
   * @returns An observable emitting object data when it is loaded or is changed.
   */
  public loadStoreObject<T>(storeObject: DataStoreObject<T>): Observable<T> {
    return this.loadObject<T>(storeObject.href).pipe(
      map(object => object.data)
    );
  }

  /**
   * Gets the {@link DataStoreObject} for the supplied key.
   *
   * @param key The URI used to access the object.
   * @param subKey The sub-key of the data.
   * @param parameters The query parameters to use.
   * @returns The requested object.
   */
  private getObject<T>(
    key: string,
    subKey: string,
    parameters?: Record<string, unknown>
  ): Observable<DataStoreObject<T>> {
    if (!this.dataStore.has(key)) {
      this.dataStore.set(key, new Map<string, ApiSubject<any>>());
    }

    if (!this.dataStore.get(key).has(subKey)) {
      this.dataStore.get(key).set(subKey, new ApiSubject<DataStoreObject<T>>(this.http, key, parameters));
    } else {
      this.dataStore.get(key).get(subKey).parameters = parameters;
    }

    return (this.dataStore as Map<string, Map<string, ApiSubject<DataStoreObject<T>>>>).get(key).get(subKey).subject$.pipe(
      tap(x => this.storeSubObjectsFrom(x.data)),
      catchError((x: unknown) => {
        if (x instanceof HttpErrorResponse && x.status === 404) {
          return of({
            status: DataObjectStatus.notFound,
            href: key
          } as DataStoreObject<T>);
        } else {
          throw x;
        }
      })
    );
  }

  /**
   * Process through the data and record any data store object in the data store.
   *
   * @param data The data to process.
   */
  private storeSubObjectsFrom(data: any): void {
    if (data !== null && data !== undefined && typeof data === 'object') {
      if (isDataStoreObject<any>(data) && data.status === DataObjectStatus.loaded) {
        this.putObject(data);
      }

      Object.values(data).filter(x => typeof x === 'object').forEach(
        x => this.storeSubObjectsFrom(x)
      );
    }
  }

  /**
   * Puts the {@link DataStoreObject} into the data store.
   *
   * @param storeObject The object to add to the data store.
   * @param parameters The query parameters to use.
   */
  private putObject<T>(
    storeObject: DataStoreObject<T>,
    parameters?: Record<string, unknown>
  ): void {
    if (!this.dataStore.has(storeObject.href)) {
      this.dataStore.set(storeObject.href, new Map<string, ApiSubject<any>>());
    }

    if (!this.dataStore.get(storeObject.href).has(undefined)) {
      this.dataStore.get(storeObject.href).set(
        undefined,
        new ApiSubject<DataStoreObject<T>>(this.http, storeObject.href, parameters, storeObject));
    }
  }

  /**
   * Gets the {@link DataStoreCollection} for the supplied key.
   *
   * @param key The URI used to access the object.
   * @param subKey The sub-key for the required instane of the object.
   * @param parameters The query parameters used to access the object.
   * @returns The requested object.
   */
  private getCollection<T>(
    key: string,
    subKey: string,
    parameters?: Record<string, unknown>
  ): Observable<DataStoreCollection<T>> {
    if (!this.dataStore.has(key)) {
      this.dataStore.set(key, new Map<string, ApiSubject<any>>());
    }

    if (!this.dataStore.get(key).has(subKey)) {
      this.dataStore.get(key).set(subKey, new ApiSubject<DataStoreCollection<T>>(this.http, key, parameters));
    } else {
      this.dataStore.get(key).get(subKey).parameters = parameters;
    }

    return this.dataStore.get(key).get(subKey).subject$.pipe(
      tap(collection => {
        this.storeSubObjectsFrom(collection);
      }),
      catchError((x: unknown) => {
        if (x instanceof HttpErrorResponse && x.status === 404) {
          return of({
            status: DataObjectStatus.notFound,
            href: key
          } as DataStoreCollection<T>);
        } else {
          throw x;
        }
      })
    );
  }

  /**
   * Notify that a new value for an object has been received.
   *
   * @param object The new value of the object.
   */
  private onObjectUpdated(object: DataStoreObject<any>): void {
    if (this.dataStore.has(object.href)) {
      switch (object.status) {
        case DataObjectStatus.loaded:
          this.dataStore.get(object.href).forEach(e => e.next(object));
          break;
        case DataObjectStatus.deleted:
          this.dataStore.get(object.href).forEach(e => e.complete());
          break;
        default:
          this.dataStore.get(object.href).forEach(e => e.refresh());
          break;
      }
    }
  }

  /**
   * Notify that a changes for a collection have been received.
   *
   * @param updates The updates to apply.
   */
  private onCollectionUpdated(updates: CollectionChangeData<any>): void {
    if (this.dataStore.get(updates.collectionHref)) {
      this.dataStore.get(updates.collectionHref).forEach((e, key) => e.refresh());
    }
  }
}

import { Rule7Type } from './rule7-type';

/**
 * Map Rule 7 API key to user-friendly names.
 */
export const rule7DisplayNames = [
  { displayText: 'Yes', value: Rule7Type.yes },
  { displayText: 'Part Rule 7', value: Rule7Type.part },
  { displayText: 'No', value: Rule7Type.no },
];

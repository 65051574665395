import { Injectable } from '@angular/core';
import moment from 'moment';

/**
 * A service to provide functionality to convert dates strings in objects to and from date objects.
 */
export interface IObjectDateParserService {
  /**
   * Convert all object fields that contain dates in iso8601 format to JavaScript Date objects.
   *
   * @param body The object to convert the dates in.
   */
  convertDates(body: any): void;

  /**
   * Convert all object fields that contain date objects to iso8601 format strings.
   *
   * @param body The object to convert the dates in.
   */
  convertIso8601(body: any): void;
}

/**
 * A service to provide functionality to convert dates strings in objects to and from date objects.
 */
@Injectable({
  providedIn: 'root'
})
export class ObjectDateParserService implements IObjectDateParserService {
  private readonly iso8601 = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/;
  private readonly aspNetTimeSpanJsonRegex = /(-)?(\d*(?=.))?.?(\d+):(\d+):(\d+).?(\d{3})?/;

  /**
   * Convert all object fields that contain dates in iso8601 format to JavaScript Date objects.
   * Convert all object fields that contain .net TimeSpan moment duration objects.
   *
   * @param body The object to convert the dates in.
   */
  public convertDates(body: any): void {
    if (body !== null && body !== undefined && typeof body === 'object') {
      for (const key of Object.keys(body)) {
        const value = body[key];
        if (typeof value === 'object') {
          this.convertDates(value);
        } else if (this.isIso8601(value)) {
          body[key] = new Date(value);
        } else if (this.isTimeSpan(value)) {
          body[key] = this.convertDurationToString(moment.duration(value));
        }
      }
    }
  }

  /**
   * Convert all object fields that contain date objects to iso8601 format strings.
   *
   * @param body The object to convert the dates in.
   */
  public convertIso8601(body: any): void {
    if (body !== null && body !== undefined && typeof body === 'object') {
      for (const key of Object.keys(body)) {
        const value = body[key];
        if (value instanceof Date) {
          body[key] = value.toJSON();
        } else if (typeof value === 'object') {
          this.convertIso8601(value);
        }
      }
    }
  }

  /**
   * Checks if the value is a date in iso8601 format.
   *
   * @param value The value to check.
   * @returns A flag indicating whether the passed value is an iso8601 date.
   */
  private isIso8601(value: any): any {
    if (value === null || value === undefined) {
      return false;
    }

    return this.iso8601.test(value);
  }

  /**
   * Checks if the value is a serialised .net time span.
   *
   * @param value The value to check.
   * @returns A flag indicating whether the passed value is a TimeSpan.
   */
  private isTimeSpan(value: any): any {
    if (value === null || value === undefined) {
      return false;
    }

    return this.aspNetTimeSpanJsonRegex.test(value);
  }

  /**
   * Convert duration to a format that the provider expects to recieve.
   *
   * @param value The duration to format.
   * @returns The duration in the format of `HH:mm:ss`.
   */
  private convertDurationToString(value: moment.Duration): string {
    let durationFormatted = null as string;

    if (value != null) {
      const hour = value.hours().toLocaleString('en-UK', { minimumIntegerDigits: 2 });
      const minute = value.minutes().toLocaleString('en-UK', { minimumIntegerDigits: 2 });
      const second = value.seconds().toLocaleString('en-UK', { minimumIntegerDigits: 2 });
      durationFormatted = `${hour}:${minute}:${second}`;
    }

    return durationFormatted;
  }
}

/**
 * Possible types of tag changes.
 */
export enum TagChangeType {
  /**
   * A tag has been added to a property value.
   */
  tagAdded = 'TagAdded',

  /**
   * A tag has been removed from a property value.
   */
  tagRemoved = 'TagRemoved'
}

/**
 * Valid data object statuses.
 */
export enum DataObjectStatus {
  notLoaded = 'NotLoaded',
  notFound = 'NotFound',
  loaded = 'Loaded',
  deleted = 'Deleted',
  updating = 'Updating'
}

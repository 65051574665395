import { DataObjectStatus } from './data-object-status';

/**
 * Data store object.
 */
export class DataStoreObject<T> {

  /**
   * A model of a Data Store Object.
   * 
   * @param key The HREF.
   */
  constructor(key: string) {
    this.href = key;
    this.status = DataObjectStatus.notLoaded;
  }

  /**
   * The href that can be used to retrieve an object. Href uniquely identifies the object.
   */
  public href: string;

  /**
   * The status of the data store object.
   */
  public status: DataObjectStatus;

  /**
   * The data.
   */
  public data?: T;
}

export const isDataStoreObject = <T>(data: any): data is DataStoreObject<T> => data !== null &&
  data !== undefined && typeof data === 'object' &&
  Object.keys(data).every(key => key === 'href' || key === 'status' || key === 'data') &&
  'href' in data && 'status' in data;

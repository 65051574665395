import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

/**
 * Service that provides uris to access the Api resources.
 */
export interface IApiService {
  /**
   * A dictionary of observables emitting api uris.
   */
  api$: Observable<{ [key: string]: string }>;
}

/**
 * A service providing uri to access the api.
 */
@Injectable({
  providedIn: 'root'
})
export class ApiService implements IApiService {

  /**
   * Creates an instance of API service.
   * 
   * @param http The HTTP client.
   */
  constructor(private http: HttpClient) { }

  /**
   * A dictionary of observables emitting api uris.
   */
  public readonly api$: Observable<{ [key: string]: string }> =
    this.http.get<{ [key: string]: string }>(environment.uriDiscoveryApiUri).pipe(
      shareReplay({ refCount: false, bufferSize: 1 })
    );
}

import { HttpClient, HttpParams } from '@angular/common/http';

import * as _ from 'lodash';

import { ReplaySubject, Observable, of, Subscriber } from 'rxjs';
import { switchMap, shareReplay } from 'rxjs/operators';

/**
 * A wrapper for an RxJS {@link ReplaySubject} tailored to data provided by a
 * RESTFul API.
 *
 * @template T The type of the data items served from this Subject.
 */
export class ApiSubject<T> {

  /**
   * The number of active subscriptions on this Subject.
   */
  private subscriptionCount = 0;

  /**
   * Determines whether this subject has ever been subscribed to.
   */
  private hasBeenSubscribed = false;

  /**
   * Determines whether this Subject has been subscribed to, had
   * no subscribers on the last garbage collection pass and has
   * not been subscribed to since.
   */
  private isCandidateForCollection = false;

  /**
   * An observable that emits a new data producer.
   */
  private readonly httpLoader$ = new ReplaySubject<Observable<T>>(1);

  private readonly dataStream$ = this.httpLoader$.pipe(
    switchMap(s => s),
    shareReplay({ refCount: false, bufferSize: 1 })
  );

  /**
   * An observable that emits the loaded object.
   */
  public readonly subject$ = new Observable(
    (subscriber: Subscriber<T>) => {
      const subscription = this.dataStream$.subscribe(subscriber);
      this.hasBeenSubscribed = true;
      this.isCandidateForCollection = false;
      this.subscriptionCount++;
      return (): void => {
        subscription.unsubscribe();
        this.subscriptionCount--;
      };
    }
  );

  /**
   * Gets a value determining whether this Subject is ready for garbage collection.
   */
  public get isReadyForCollection(): boolean {
    let result = false;

    if (this.subscriptionCount < 1) {
      if (this.isCandidateForCollection) {
        result = true;
      } else {
        this.isCandidateForCollection = this.hasBeenSubscribed;
      }
    }

    return result;
  }

  /**
   * The query parameters that this Subject should use in a format
   * suitable for use in {@link HttpClient} requests.
   */
  private httpParams: HttpParams;

  /**
   * The parameters that this Subject should use as supplied by the
   * caller.
   */
  private suppliedParams: Record<string, unknown>;

  /**
   * Sets the parameters that this subject will use for HTTP requests.
   */
  public set parameters(values: Record<string, unknown>) {
    if (
      values !== undefined &&
      !_.isEqual(this.suppliedParams, values)
    ) {
      this.suppliedParams = values;
      this.httpParams = this.createHttpParams(values);
      this.refresh();
    }
  }

  /**
   * Creates an instance of api subject.
   *
   * @param http The instance of {@link HttpClient} that this Subject
   *             should use to communicate with the API.
   * @param href The URI of the object served from this Subject.
   * @param parameters Query parameters that should be used when
   *                   calling the API to get this object.
   * @param initialValue The initial value of the object.
   */
  constructor(
    private readonly http: HttpClient,
    public readonly href: string,
    parameters?: Record<string, unknown>,
    initialValue?: T
  ) {
    this.suppliedParams = parameters;
    this.httpParams = this.createHttpParams(parameters);
    if (initialValue === undefined) {
      this.refresh();
    } else {
      this.next(initialValue);
    }
  }

  /**
   * Fire on completion of httpLoader$.
   */
  public complete(): void {
    if (!this.httpLoader$.closed) {
      this.httpLoader$.complete();
    }
  }

  /**
   * Load the next API subject.
   * 
   * @param data An observable of API.
   */
  public next(data: T): void {
    this.httpLoader$.next(of(data));
  }

  /**
   * Causes the Subject to query to API to get the current value of
   * the object and to publish it to subscribers.
   */
  public refresh(): void {
    const options = this.httpParams ? { params: this.httpParams } : {};
    this.httpLoader$.next(this.http.get<T>(this.href, options));
  }

  /**
   * Generates HTTP query parameters from the passed object in a format
   * suitable for use in {@link HttpClient} requests.
   *
   * @param params The passed object to generate values from.
   * @returns The formatted {@link HttpParams} object.
   */
  private createHttpParams(params: Record<string, unknown>): HttpParams {
    let httpParams: HttpParams = new HttpParams();
    if (params) {
      Object.keys(params).forEach(param => {
        if (params[param] !== null && params[param] !== undefined) {
          if (params[param] instanceof Date) {
            httpParams = httpParams.set(param, (params[param] as Date).toUTCString());
          } else if (params[param] instanceof Array) {
            const arrayParamValues = params[param] as Array<any>;
            arrayParamValues.forEach(value => {
              httpParams = httpParams.append(param, value);
            });
          } else {
            httpParams = httpParams.set(param, params[param] as string | number);
          }
        }
      });
    }
    return httpParams;
  }
}

/**
 * Data Rule types known to the system.
 */
export const dataRuleTypes = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Personal: 'Personal',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Pencil: 'Pencil',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  HARTs: 'HARTs',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Agent: 'Agent',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Mapping: 'Mapping',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Manual: 'Manual',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ACE: 'ACE',
};

import { Injectable } from '@angular/core';
import { RlasApiModule } from './rlas-api.module';
import { ApiService } from '../api/api.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { switchMap, shareReplay } from 'rxjs/operators';
import { SearchCountResponse, SearchFilter } from './model';
import { Observable } from 'rxjs';

export interface ISearchService {

  /**
   * Gets the count of searches for a given filter.
   * 
   * @param filter The search filter.
   */
  getSearchCount$(filter: SearchFilter): Observable<SearchCountResponse>;
}

/**
 * A service to preform operations on HARTs Search API.
 */
@Injectable({
  providedIn: RlasApiModule
})
export class SearchService implements ISearchService {

  /**
   * Creates an instance of Search Service.
   *
   * @param apiService The {@link ApiService} instance to use.
   * @param httpClient The {@link HttpClient} instance to use.
   */
  constructor(
    private apiService: ApiService,
    private httpClient: HttpClient
  ) {
  }

  /**
   * @inheritdoc
   */
  public getSearchCount$(filter: SearchFilter): Observable<SearchCountResponse> {
    const params = this.createHttpParams(filter as Record<string, unknown>);
    return this.apiService.api$.pipe(
      switchMap(x => this.httpClient.get<SearchCountResponse>(x['SearchesController.GetSearchCount'], { params })),
      shareReplay({ refCount: false, bufferSize: 1 })
    );
  }

  /**
   * Generates HTTP query parameters from the passed object in a format
   * suitable for use in {@link HttpClient} requests.
   *
   * @param params The passed object to generate values from.
   * @returns The formatted {@link HttpParams} object.
   */
  private createHttpParams(params: Record<string, unknown>): HttpParams {
    let httpParams: HttpParams = new HttpParams();
    if (params) {
      Object.keys(params).forEach(param => {
        if (params[param] !== null && params[param] !== undefined) {
          if (params[param] instanceof Date) {
            httpParams = httpParams.set(param, (params[param] as Date).toUTCString());
          } else if (params[param] instanceof Array) {
            const arrayParamValues = params[param] as Array<any>;
            arrayParamValues.forEach(value => {
              httpParams = httpParams.append(param, value);
            });
          } else {
            httpParams = httpParams.set(param, params[param] as string | number);
          }
        }
      });
    }
    return httpParams;
  }
}

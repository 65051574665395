import { Injectable } from '@angular/core';
import { ApiService } from '../api';
import { switchMap, shareReplay, map, filter } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { User, SecurityGroup } from './model';
import { Observable, combineLatest } from 'rxjs';
import * as _ from 'lodash';
import { DataStoreService } from '../data-store';
import { AuthService } from '../authentication/services';

export interface IUserService {
  users$: Observable<User[]>;
  me$: Observable<User>;
}

/**
 * A service to handle HARTs User API calls.
 */
@Injectable({
  providedIn: 'root'
})
export class UserService implements IUserService {

  /**
   * Creates an instance of User Service.
   * 
   * @param authService The {@link AuthService} instance to use.
   * @param apiService The {@link ApiService} instance to use.
   * @param dataStore The {@link DataStoreService} instance to use.
   * @param httpClient The {@link HttpClient} instance to use.
   */
  constructor(
    private authService: AuthService,
    private apiService: ApiService,
    private dataStore: DataStoreService,
    private httpClient: HttpClient) { }

  public readonly users$ = this.apiService.api$.pipe(
    switchMap(x => this.httpClient.get<User[]>(x.UsersController)),
    map(users => _.sortBy(users, u => u.name)),
    shareReplay({ refCount: false, bufferSize: 1 })
  );

  public readonly me$ = combineLatest([this.apiService.api$, this.authService.account$]).pipe(
    filter(([urls, account]) => account !== null),
    switchMap(([urls, account]) => this.dataStore.loadObjectNoCache<User>(urls['UsersController.GetCurrentUser'])),
    map(user => user.data),
    shareReplay({ refCount: false, bufferSize: 1 })
  );

  /**
   * A stream that emits a value to determine whether the current user can access configurations.
   */
  public readonly iHaveRlasConfigAccess$ = this.me$.pipe(
    map(user => user.groups.some(group => group === SecurityGroup.rlasConfigAccess)),
    shareReplay({ refCount: false, bufferSize: 1 })
  );

  /**
   * A stream that emits a value to determine whether the current user can edit configurations.
   */
  public readonly iHaveRlasConfigEdit$ = this.me$.pipe(
    map(user => user.groups.some(group => group === SecurityGroup.rlasConfigEdit)),
    shareReplay({ refCount: false, bufferSize: 1 })
  );

  /**
   * A stream that emits a value to determine whether the current user can access default answers.
   */
  public readonly iHaveRlasDefaultAnswersAccess$ = this.me$.pipe(
    map(user => user.groups.some(group => group === SecurityGroup.rlasDefaultAnswersAccess)),
    shareReplay({ refCount: false, bufferSize: 1 })
  );

  /**
   * A stream that emits a value to determine whether the current user can edit default answers.
   */
  public readonly iHaveRlasDefaultAnswersEdit$ = this.me$.pipe(
    map(user => user.groups.some(group => group === SecurityGroup.rlasDefaultAnswersEdit)),
    shareReplay({ refCount: false, bufferSize: 1 })
  );

  /**
   * A stream that emits a value to determine whether the current user can edit the global authority.
   */
  public readonly iHaveRlasGlobalAuthorityEdit$ = this.me$.pipe(
    map(user => user.groups.some(group => group === SecurityGroup.rlasGlobalAuthorityEdit)),
    shareReplay({ refCount: false, bufferSize: 1 })
  );

  /**
   * A pipeline that emits a list of ace users.
   */
  public readonly aceUsers$ = this.apiService.api$.pipe(
    switchMap(x => this.httpClient.get<User[]>(x.UsersController + '?group=aceUsers')),
    map(users => _.sortBy(users, u => u.name)),
    shareReplay({ refCount: false, bufferSize: 1 })
  );

  /**
   * A pipeline that emits a combined list of harts and ace users.
   */
  public readonly hartsAndAceUsers$: Observable<User[]> = combineLatest([this.users$, this.aceUsers$]).pipe(
    map(([hartsUsers, aceUsers]) => [...hartsUsers, ...aceUsers]),
    map(users => users.sort((a, b) => a.name.localeCompare(b.name))),
  );
}

/**
 * LA Details types known to the system.
 */
export const laDetailsTypes = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  LandChargesSummary: 'LaDetailsLandChargesSummary',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Organisation: 'Organisation',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  AuthoritySuppliers: 'AuthoritySuppliers',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  EvidenceCapture: 'EvidenceCapture',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  AuthorityGroup: 'AuthorityGroup',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Department: 'Department',
};

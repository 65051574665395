import { inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AccountInfo, AuthenticationResult, EventType, InteractionStatus } from '@azure/msal-browser';
import { filter, lastValueFrom, map, Observable, pairwise, shareReplay, startWith, switchMap, tap } from 'rxjs';
import { environment } from 'src/environments/environment';

export interface IAuthService {

  /**
   * Gets a stream of boolean indicating if there is an authenticated user.
   */
  readonly isAuthenticated$: Observable<boolean>;

  /**
   * Gets a stream of Account of the currently authenticated used.
   */
  readonly account$: Observable<AccountInfo>;

  /**
   * Gets an access token for the specified endpoint.
   *
   * @param endpoint Endpoint to get access token for.
   */
  getAccessTokenForEndpoint(endpoint: string): Promise<string>;

  /**
   * Logs the user out.
   */
  logout(): void;
}

/**
 * Service that provides access to the current user data obtained from authentication.
 */
@Injectable({
  providedIn: 'root'
})
export class AuthService implements IAuthService {
  /**
   * The {@link MsalService} instance to use.
   */
  private readonly msalService = inject(MsalService);

  /**
   * The {@link MsalBroadcastService} instance to use.
   */
  private readonly broadcastService = inject(MsalBroadcastService);

  /**
   * A stream of the Account of the currently authenticated user.
   */
  public readonly account$ = this.broadcastService.inProgress$.pipe(
    filter(status => status === InteractionStatus.None),
    map(() => this.getAccount()),
    startWith(undefined),
    takeUntilDestroyed(),
    shareReplay({ bufferSize: 1, refCount: false })
  );

  /**
   * A stream of values indicating if there is currently an authenticated user.
   */
  public readonly isAuthenticated$ = this.account$.pipe(
    map(account => account != null),
    shareReplay({ refCount: false, bufferSize: 1 })
  );

  /**
   * Gets an access token for the specified endpoint.
   *
   * @param endpoint Endpoint to get access token for.
   * @returns The access token.
   */
  public getAccessTokenForEndpoint(endpoint: string): Promise<string> {

    const scopes: string[] | undefined = [
      ...(environment.msalInterceptorConfig.protectedResourceMap as Map<string, string[]>)
    ].filter(([key, value]) => endpoint.startsWith(key))
      .map(([key, value]) => ({ key, value }))
      .reduce((a, b) => a.key.length > b.key.length ? a : b, { key: '', value: [] })?.value;

    if (scopes) {
      return lastValueFrom(this.msalService.acquireTokenSilent({
        scopes,
        account: this.getAccount()
      }).pipe(
        map(r => r.accessToken)
      ));
    } else {
      return Promise.resolve('');
    }
  }

  /**
   * Logs the user out.
   */
  public logout(): void {
    this.msalService.logout();
  }

  /**
   * Gets the current user's account.
   *
   * @returns The current user's account.
   */
  private getAccount(): AccountInfo {
    return this.msalService.instance.getActiveAccount() ?? this.msalService.instance.getAllAccounts()[0];
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApiModule } from 'src/app/api/api.module';
import { ChatterModule } from 'src/app/chatter/chatter.module';

/**
 * Module for Data Store.
 */
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ChatterModule,
    ApiModule,
  ]
})
export class DataStoreModule { }

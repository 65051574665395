/**
 * Shortcode types known to the system.
 */
export const shortcodeTypes = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ShortcodesPlain: 'Shortcodes',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  LandChargesCommonEntries: 'LandChargesCommonEntries',
};


/**
 * Details of Rule 7 types returned by API.
 */
export enum Rule7Type {
  /**
   * The yes value.
   */
  yes = 'Yes',

  /**
   * The part rule 7 value.
   */
  part = 'Part',

  /**
   * The no value.
   */
  no = 'No',
}

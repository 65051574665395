import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApiModule } from 'src/app/api/api.module';
import { DataStoreModule } from 'src/app/data-store/data-store.module';

/**
 * Module for RLAS APIs.
 */
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    DataStoreModule,
    ApiModule,
  ]
})
export class RlasApiModule { }

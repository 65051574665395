/**
 * Answer types known to the system.
 */
export const answerTypes = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  PlainText: 'PlainText',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  LocalPlanPolicies: 'LocalPlanPolicies',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Date: 'Date',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  RollingDate: 'Rolling Date',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  LandChargesRecords: 'LandChargesRecords',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  LandChargesSummary: 'LandChargesSummary',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  PlanningRecords: 'PlanningRecords',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  BuildingControlRecords: 'BuildingControlRecords',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  RdwsDefaultAnswer: 'RDWS Default Answer',
};

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Tag } from './model';
import { RlasApiModule } from './rlas-api.module';
import { HttpClient } from '@angular/common/http';
import { ConfigurationRecord } from './model/configuration-record';
import { DataStoreObject } from '../data-store/model';
import { map } from 'rxjs/operators';

/**
 * A service to manage authority tags.
 */
export interface ITagService {
  /**
   * A pseudo tag to use on global entries.
   */
  globalTag: Tag;

  /**
   * Creates a new tag, by cloning an existing tag.
   *
   * @param href The href to send the changes to.
   * @param tag The tag detail.
   * @param sourceTagName The tag name to clone.
   * @param authorities A list of authorities to apply the tag to.
   */
  createTag(href: string, tag: Tag, sourceTagName: string, authorities: string[]): Observable<Tag[]>;

  /**
   * Mark a tag as active.
   *
   * @param authorityId The authorityId to apply changes to.
   * @param href The href to send the changes to.
   * @param tag The tag detail.
   * @param updateActiveSearches A flag to indicate if in flight searches should be updated.
   */
  activateTag(authorityId: string, href: string, tag: Tag, updateActiveSearches: boolean): Observable<Tag[]>;

  /**
   * Delete a tag.
   *
   * @param authorityId The authorityId to apply changes to.
   * @param href The href to send the changes to.
   * @param tag The tag detail.
   */
  deleteTag(authorityId: string, href: string, tag: Tag): Observable<Tag[]>;
}

/**
 * @inheritdoc
 */
@Injectable({
  providedIn: RlasApiModule
})
export class TagService implements ITagService {

  /**
   * Creates an instance of Tag Service service.
   *
   * @param httpClient The {@link HttpClient} instance to use.
   */
  constructor(
    private httpClient: HttpClient
  ) { }

  /**
   * @inheritdoc
   */
  public readonly globalTag: Tag = {
    name: 'Global',
    description: 'This will be used where there is no value for the authority',
    style: 'tag-standard',
    isActive: false,
    isDeleted: false,
    isReadOnly: true,
    isSystem: true
  };

  /**
   * @inheritdoc
   */
  public createTag(href: string,
    tag: Tag,
    sourceTagName: string,
    authorities: string[]): Observable<Tag[]> {
    const record: ConfigurationRecord = this.makeRecord(tag, sourceTagName, false, false, false, authorities);
    return this.updateTag(href, record);
  }

  /**
   * @inheritdoc
   */
  public activateTag(authorityId: string, href: string, tag: Tag, updateActiveSearches: boolean): Observable<Tag[]> {
    const record: ConfigurationRecord = this.makeRecord(tag, null, updateActiveSearches, true, false, [authorityId]);
    return this.updateTag(href, record);
  }

  /**
   * @inheritdoc
   */
  public deleteTag(authorityId: string, href: string, tag: Tag): Observable<Tag[]> {
    const record: ConfigurationRecord = this.makeRecord(tag, null, false, false, true, [authorityId]);
    return this.updateTag(href, record);
  }

  /**
   * Update a tag.
   * 
   * @param href The HREF of the tag.
   * @param record The tag.
   * @returns An Observable of tags.
   */
  private updateTag(href: string, record: ConfigurationRecord): Observable<Tag[]> {
    return this.httpClient.post<DataStoreObject<Tag[]>>(href, record).pipe(
      map(response => response.data)
    );
  }

  /**
   * Make a Configuration Record.
   * 
   * @param tag The tag.
   * @param sourceTagName The source tag name.
   * @param updateActiveSearches A value to indicate if active searches were updated.
   * @param isActive  A value to indicate if tag is active.
   * @param isDeleted A value to indicate if tag is deleted.
   * @param authorities A list of authorities.
   * @returns A Configuration Record.
   */
  private makeRecord(
    tag: Tag,
    sourceTagName: string,
    updateActiveSearches: boolean,
    isActive: boolean,
    isDeleted: boolean,
    authorities: string[]): ConfigurationRecord {

    const record: ConfigurationRecord = {
      sourceTagName,
      updateActiveSearches,
      tag: {
        name: tag.name,
        description: tag.description,
        style: tag.style,
        isReadOnly: tag.isReadOnly,
        isSystem: tag.isSystem,
        isActive,
        isDeleted,
      },
      authorities,
    };

    return record;
  }
}

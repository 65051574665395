import { Injectable } from '@angular/core';
import { ApiService } from '../api';
import { switchMap, shareReplay, map } from 'rxjs/operators';

import { SearchClerk } from './model';
import { Observable, combineLatest, of } from 'rxjs';
import { DataStoreService, DataStoreObject } from '../data-store';
import { RlasApiModule } from './rlas-api.module';

/**
 * A service to fetch Search Clerk data.
 */
export interface ISearchClerkService {

  /**
   * A stream of current Search Clerks.
   */
  searchClerks$: Observable<SearchClerk[]>;

  /**
   * A stream of ACE users.
   */
  aceUsers$: Observable<SearchClerk[]>;
}

/**
 * A service to fetch Search Clerk data.
 */
@Injectable({
  providedIn: RlasApiModule
})
export class SearchClerkService implements ISearchClerkService {

  /**
   * Creates an instance of Search Clerk service.
   *
   * @param apiService The {@link ApiService} instance to use.
   * @param dataStoreService The {@link DataStoreService} instance to use.
   */
  constructor(
    private apiService: ApiService,
    private dataStoreService: DataStoreService) { }

  /**
   * @inheritdoc
   */
  public readonly searchClerks$ = this.apiService.api$.pipe(
    switchMap(x => this.dataStoreService.loadObject<DataStoreObject<SearchClerk>[]>(x.SearchClerkController)),
    switchMap(x => this.GetUsersInternal$(x.data)),
    shareReplay({ refCount: false, bufferSize: 1 }),
  );

  /**
   * @inheritdoc
   */
  public readonly aceUsers$ = this.apiService.api$.pipe(
    switchMap(x => {
      const url = new URL(x.SearchClerkController);
      url.searchParams.append('usertype', 'aceUser');
      return this.dataStoreService.loadObject<DataStoreObject<SearchClerk>[]>(url.href);
    }),
    switchMap(x => this.GetUsersInternal$(x.data)),
    shareReplay({ refCount: false, bufferSize: 1 }),
  );

  /**
   * An internal method to get users.
   * 
   * @param userData The user data store items.
   * @returns A stream that will emit the search clerks.
   */
  private GetUsersInternal$(userData: DataStoreObject<SearchClerk>[]): Observable<SearchClerk[]> {
    return of(userData).pipe(
      switchMap(x =>
        combineLatest(userData.map(m =>
          this.dataStoreService.loadObject<SearchClerk>(m.href)))),
      map(x => x.map(m => m.data).sort((clerk, otherclerk) => (clerk.name > otherclerk.name ? 1 : -1))));
  }
}

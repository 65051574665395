/**
 * Authority supplier type types that are known to the system.
 */
export enum SupplierType {

  /**
   * A National Park supplier.
   */
  // eslint-disable-next-line @typescript-eslint/naming-convention
  CountyCouncil = 'County Council',

  /**
   * The Global supplier.
   */
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Global = 'Global',

  /**
   * The Highways supplier.
   */
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Highways = 'Highways',

  /**
   * A Local Authority supplier.
   */
  // eslint-disable-next-line @typescript-eslint/naming-convention
  LocalAuthority = 'Local Authority',

  /**
   * A National Park supplier.
   */
  // eslint-disable-next-line @typescript-eslint/naming-convention
  NationalPark = 'National Park',

  /**
   * A Water Company supplier.
   */
  // eslint-disable-next-line @typescript-eslint/naming-convention
  WaterCompany = 'Water Company',
}


/**
 * Help types known to the system.
 */
export const helpTypes = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  HelpText: 'Help Text',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  DevelopmentHelp: 'Development Help',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  PamDevelopmentList: 'PamDevelopmentList'
};

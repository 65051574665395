import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { AuthService } from '../authentication';
import { switchMap } from 'rxjs/operators';

/**
 * An HTTP interceptor to refresh the MSAL access token.
 */
@Injectable()
export class TokenRefreshInterceptor implements HttpInterceptor {

  /**
   * Creates an instance of token refresh interceptor.
   *
   * @param authService An instance of the {@link AuthService}.
   */
  constructor(private authService: AuthService) { }

  /**
   * Intercepts HTTP requests and gets or refreshes the MSAL access token.
   *
   * @param request The outgoing request object to handle.
   * @param next The next interceptor in the chain, or the backend if no interceptors remain in the chain.
   * @returns Intercept an observable of the event stream.
   */
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.authService.getAccessTokenForEndpoint(request.url))
      .pipe(
        switchMap(_ => next.handle(request))
      );
  }
}

/**
 * Possible types of history records.
 */
export enum ChangeType {

  /**
   * A record describing a change in property value.
   */
  valueChange = 'ValueChange',

  /**
   * A record describing adding a configuration.
   */
  configurationAddition = 'ConfigurationAddition',

  /**
   * A record describing activation of configuration.
   */
  configurationActivation = 'ConfigurationActivation',

  /**
   * A record describing deleting a configuration.
   */
  configurationDeletion = 'ConfigurationDeletion',

  /**
   * A record describing a change in an unknown value (to be removed once we have other real types).
   */
  unknownChange = 'UnknownChange'

}

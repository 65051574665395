import { MsalInterceptorConfiguration, MsalGuardConfiguration } from '@azure/msal-angular';
import { BrowserCacheLocation, LogLevel, InteractionType, Configuration } from '@azure/msal-browser';

const msalConfig: Configuration = {
  auth: {
    authority: 'https://login.microsoftonline.com/d6bb6b53-5c8d-4396-9886-ad5970a41ad2',
    clientId: 'fdcc53a6-655e-486e-a038-931708003a3a',
    redirectUri: 'https://staging-rlas.infotrack.co.uk',
    postLogoutRedirectUri: `${window.location.origin}/logged-out`
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage
  },
  system: {
    navigateFrameWait: 10,
    loggerOptions: {
      logLevel: LogLevel.Error,
      piiLoggingEnabled: false
    }
  }
};

const msalInterceptorConfig: MsalInterceptorConfiguration = {
  interactionType: InteractionType.Redirect,
  protectedResourceMap: new Map<string, string[]>([
    ['https://staging-rlas-api.infotrack.co.uk', ['api://fdcc53a6-655e-486e-a038-931708003a3a/access_as_user']],
    ['https://stagesearch.infotrack.co.uk/ukgis/api', ['fdcc53a6-655e-486e-a038-931708003a3a/access_as_user']]
  ])
};

const msalGuardConfig: MsalGuardConfiguration = {
  interactionType: InteractionType.Redirect,
  authRequest: {
    scopes: ['api://fdcc53a6-655e-486e-a038-931708003a3a/access_as_user'],
    redirectUri: 'https://staging-rlas.infotrack.co.uk',
  },
  loginFailedRoute: '/logged-out'
};

const adminBoundariesUri = '/geoserver/AdministrativeBoundaries';

export const environment = {
  production: true,
  msalConfig,
  msalInterceptorConfig,
  msalGuardConfig,
  chatterUri: 'https://staging-rlas-api.infotrack.co.uk/api/v4.0/chatter',
  gcInterval: 600,
  uriDiscoveryApiUri: 'https://staging-rlas-api.infotrack.co.uk/api/v4.0',
  mapping: {
    laBoundariesDataSource: {
      href: adminBoundariesUri,
      layers: 'AdministrativeBoundaries:laboundaries',
      styles: 'AdministrativeBoundaries:HartsLaBoundaries'
    },
    nationalParksDataSource: {
      href: adminBoundariesUri,
      layers: 'AdministrativeBoundaries:nationalparks',
      styles: 'AdministrativeBoundaries:HartsNationalParks'
    },
    wfsUri: '/geoserver/{namespace}/wfs',
    wmtsUri: 'https://staging-rlas.infotrack.co.uk/geoserver/gwc/service/wmts',
    roadsNamespace: 'roads'
  },
  supplierApiUri: 'https://stagesearch.infotrack.co.uk/ukgis/api/suppliers',
  nationalparkApiUri: 'https://stagesearch.infotrack.co.uk/ukgis/api/nationalparks',
  trainingTeamEmail: 'HARTsTraining@infotrack.co.uk',
  sherlockMultiDeleteMax: 10
};
